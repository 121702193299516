import React from "react"

import Seo from "../components/seo"

export default function Disclaimer() {
  const heroTitle = "Disclaimer"
  return (
    <>
      <Seo title={heroTitle} />
      <section class="c-section c-section-sm">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12">
              <div class="innerpage--content innerpage--content-sm m-0 p-0">
                <h2 class="h2 h2-sm">{heroTitle}</h2>
                <p>
                  The content of this website is for general information purposes only and under no
                  circumstances is the information therein to be used or considered as constituting
                  an offer to buy or sell, or solicitation of any offer to buy or offer or
                  recommendation to acquire or dispose of any security, commodity or investment or
                  to engage in any other transaction. The information contained herein is not to be
                  used for any other purpose or made available to anyone not directly associated
                  with the determination of any such interest.
                </p>
                <p>
                  Apricus Wealth (hereinafter referred to as “investment adviser”) offers different
                  products and services. These products and services are offered to investors in
                  accordance with applicable laws and regulations. The information provided on this
                  website is not intended for distribution to, or use by, any person or entity in
                  any jurisdiction or country where such distribution or use would be contrary to
                  law or regulation. All persons and entities accessing this website do so of their
                  own initiative and are responsible for compliance with such applicable local laws
                  and regulations. This website is not directed to any person in any jurisdiction
                  where the publication or availability of this website is prohibited by reason of
                  that person’s investment profile, nationality, residence or otherwise.
                </p>
                <p>
                  The contents of this website may contain forward-looking statements that are based
                  on beliefs, assumptions, current expectations, estimates, and projections about
                  the financial industry, the economy, investment adviser, or its investments.
                </p>
                <p>
                  Any opinions or statements expressed herein are subject to change. The information
                  contained herein does not and does not attempt to, disclose all the risks and
                  other significant aspects of entering into any transaction. For tax consequences,
                  each investor is advised to consult his/her own professional tax adviser.
                </p>
                <p>
                  As with any investments in listed securities, the value of the portfolio may go up
                  or down depending on the various factors and forces affecting the capital markets.
                </p>
                <p>
                  Due to the global nature of the internet, this Website may be accessed by users in
                  countries other than India. Investment adviser makes no warranties that materials
                  on this website are appropriate or available for use in such jurisdictions. Where
                  it is illegal or prohibited in the user’s country of origin to access or use this
                  website, then the user should refrain from doing so. Those who choose to access
                  this website from outside India do so on their own initiative and are responsible
                  for compliance with all applicable laws and regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
